"use client";
import Image from "next/image";
import { Link } from "@/i18n/routing";
import { useTranslations } from "next-intl";
import { Button } from "./custom";
import { useAccountType } from "@/app/context/useAccountType";
export default function KycBanner() {
  const t = useTranslations();
  const { accountType, SwitchAccount } = useAccountType();
  return (
    <div className="relative mb-2 mt-4 flex aspect-[1920/1204] min-h-[220px] w-full items-center sm:aspect-[1920/655] sm:min-h-[320px]">
      <div className="absolute inset-0 z-50 flex h-fit items-center justify-between px-4 md:px-0">
        <h1 className="text-[20px] font-bold">{t("dashboard")}</h1>
        <Button radius="full" className="" onPress={() => SwitchAccount()}>
          Switch to {accountType === "CELN" ? "CFD" : "CELN"}
        </Button>
      </div>
      <Image
        src="/assets/img/banner1.webp"
        alt="banner"
        className="absolute inset-0 hidden size-full mt-3 object-cover object-top px-4 md:block md:px-0"
        width={1920}
        height={655}
      />
      <Image
        src="/assets/img/banner1-m.webp"
        alt="banner"
        className="absolute inset-0 mt-12 size-full object-contain object-top px-4 md:hidden md:px-0"
        width={1920}
        height={1204}
      />
      <div className="z-[1] px-4 md:px-0">
        <div className="z-[1] w-3/4 p-3 pt-12 min-[365px]:w-1/2 sm:p-4 lg:pt-14 xl:pt-16">
          <h2 className="xs:text-[1.2em] mb-3 text-[1em] font-bold leading-[1.2em] text-[#212121] md:text-[1.5em]">
            {t("kyc-banner.text1")}
          </h2>
          <p className="xs:text-[0.85em] mb-4 text-tiny leading-none text-[#212121]">{t("kyc-banner.text2")}</p>
          <Link href="/kyc" className="black_btn block w-fit px-6 max-[365px]:text-[14px]">
            {t("kyc-banner.start-now")}
          </Link>
        </div>
      </div>
    </div>
  );
}

// return (<div className="relative mb-2 mt-[-20px] flex min-h-[260px] w-full items-center md:mb-4">
//   <Image
//     src="/assets/img/banner1.webp"
//     alt="banner"
//     className="absolute block min-h-[240px] w-full object-cover object-center"
//     width={1920}
//     height={655}
//   />

//   {/* To-Do: Hide this banner after KYC Verified */}

//   <div className="z-[1] w-3/4 p-3 pt-12 min-[365px]:w-1/2 sm:p-4 lg:pt-14 xl:pt-16">
//     <h2 className="mb-3 text-[1em] font-bold leading-[1.2em] text-[#212121] xs:text-[1.2em] md:text-[1.5em]">
//       Complete Your KYC Verification Now!
//     </h2>
//     <p className="mb-4 text-[0.75em] text-[#212121] xs:text-[0.85em]">
//       KYC is important as it allows you to purchase CELN Funds and access other features.
//     </p>
//     <Link href="/kyc" className="black_btn block w-fit px-6 max-[365px]:text-[14px]">
//       Start Now
//     </Link>
//   </div>
// </div>);
