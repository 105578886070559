"use client";

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

type AccountTypeContextType = {
  accountType: "CELN" | "CFD";
  SwitchAccount: () => void;
};

const AccountTypeContext = createContext<AccountTypeContextType | undefined>(undefined);

const AccountTypeProvider = ({ children }: { readonly children: ReactNode }) => {
  const [accountType, setAccountType] = useState<"CELN" | "CFD">("CELN");
  const SwitchAccount = () => {
    setAccountType(accountType === "CELN" ? "CFD" : "CELN");
    localStorage.setItem("accountType", accountType === "CELN" ? "CFD" : "CELN");
  };

  useEffect(() =>
  { 
    if (typeof window !== "undefined") {
      const accountType = localStorage.getItem("accountType");
      if (accountType) {
        setAccountType(accountType as "CELN" | "CFD");
      }
    }
  }, [accountType]);

  return <AccountTypeContext.Provider value={{ accountType, SwitchAccount }}>{children}</AccountTypeContext.Provider>;
};

const useAccountType = () => {
  const context = useContext(AccountTypeContext);
  if (context === undefined) {
    throw new Error("useAccountType must be used within a AccountTypeProvider");
  }

  return context;
};

export { AccountTypeProvider, useAccountType };
