import dynamic from "next/dynamic";
import { memo } from "react";
const Ticker = dynamic(() => import("react-ts-tradingview-widgets").then((w) => w.Ticker), {
  ssr: false,
});
const MarketOverview = dynamic(() => import("react-ts-tradingview-widgets").then((w) => w.MarketOverview), {
  ssr: false,
});

export const TickerTapeWidget = memo(() => (
  <>
    <div className="h-[25rem] w-full">
      <MarketOverview
        copyrightStyles={{ parent: { display: "none" }, link: { display: "none" } }}
        colorTheme="light"
        isTransparent
        showSymbolLogo
        locale="en"
        autosize
        showChart
        // plotLineColorGrowing="#FFCA05"
        plotLineColorFalling="#FFCA05"
        belowLineFillColorFalling="#FFCA0555"
        belowLineFillColorFallingBottom="#FFCA0502"
        tabs={[
          {
            title: "Forex",
            originalTitle: "Forex",
            symbols: [
              { d: "XAUUSD", s: "OANDA:XAUUSD" },
              { d: "EURUSD", s: "OANDA:EURUSD" },
              { d: "GBPUSD", s: "OANDA:GBPUSD" },
              { d: "AUDUSD", s: "OANDA:AUDUSD" },
              { d: "NZDUSD", s: "OANDA:NZDUSD" },
              { d: "USDJPY", s: "OANDA:USDJPY" },
              { d: "USDCAD", s: "OANDA:USDCAD" },
              { d: "USDCHF", s: "OANDA:USDCHF" },
            ],
          },
        ]}
      />
    </div>
    {/* <Ticker
      copyrightStyles={{ parent: { display: "none" }, link: { display: "none" } }}
      colorTheme="light"
      isTransparent
      showSymbolLogo
      locale="en"
      symbols={[
        { title: "A", proName: "OANDA:EURUSD" },
        { title: "B", proName: "OANDA:XAUUSD" },
        { title: "C", proName: "OANDA:GBPUSD" },
        { title: "D", proName: "OANDA:AUDUSD" },
        { title: "E", proName: "OANDA:NZDUSD" },
        { title: "F", proName: "OANDA:USDJPY" },
        { title: "G", proName: "OANDA:USDCAD" },
        { title: "H", proName: "OANDA:USDCHF" },
      ]}
    /> */}
    {/* <div className="hidden bg-black/5 px-4 min-[520px]:block">
      <Ticker
        copyrightStyles={{ parent: { display: "none" }, link: { display: "none" } }}
        colorTheme="light"
        isTransparent
        showSymbolLogo
        locale="en"
        symbols={[
          // { title: "Bitcoin", proName: "BINANCE:BTCUSDT" },
          // { title: "Ethereum", proName: "BINANCE:ETHUSDT" },
        ]}
      />
    </div>
    <div className="hidden bg-black/5 px-4 max-[519px]:block">
      <Ticker
        copyrightStyles={{ parent: { display: "none" }, link: { display: "none" } }}
        colorTheme="light"
        isTransparent
        showSymbolLogo
        locale="en"
        symbols={[
          { title: "Bitcoin", proName: "BINANCE:BTCUSDT" },
          // { title: "Ethereum", proName: "BINANCE:ETHUSDT" },
        ]}
      />
    </div>
    <div className="hidden bg-black/5 px-4 max-[519px]:block">
      <Ticker
        copyrightStyles={{ parent: { display: "none" }, link: { display: "none" } }}
        colorTheme="light"
        isTransparent
        showSymbolLogo
        locale="en"
        symbols={[
          // { title: "Bitcoin", proName: "BINANCE:BTCUSDT" },
          { title: "Ethereum", proName: "BINANCE:ETHUSDT" },
        ]}
      />
    </div> */}
  </>
));

export default TickerTapeWidget;
