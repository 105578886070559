"use client";

import { createContext, memo, useContext, useState, type ReactNode } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, useDisclosure, ModalProps, ModalFooter } from "@nextui-org/react";
import AlertSvg from "@/components/svg/alert-svg";
import SuccessSvg from "@/components/svg/success-svg";
import { useTranslations } from "next-intl";
import { Button } from "@/components/custom";

const useKYCNotVerifiedModal = (callback: () => void) => {
  const t = useTranslations();
  const [Component, disclosure] = useModal({
    content: () => (
      <div className="flex size-full flex-col items-center justify-center gap-y-4">
        <AlertSvg />
        <div className="text-lg font-bold">{t("kyc-incomplete.text1")}</div>
        <p className="text-center text-sm !leading-normal text-gray-400">{t("kyc-incomplete.text2")}</p>
        <Button onPress={() => callback?.()} className="w-full">
          {t("okay")}
        </Button>
      </div>
    ),
  });
  return [Component, disclosure] as const;
};

const TemplateModalContext = createContext<{ openKYCNotVerifiedModal: () => void } | null>(null);
export const TemplateModalProvider = ({ children }: { children: ReactNode }) => {
  const [KYCModal, kycDis] = useKYCNotVerifiedModal(() => {
    kycDis.onClose();
  });

  const openKYCNotVerifiedModal = () => {
    kycDis.onOpen();
  };

  return (
    <TemplateModalContext.Provider value={{ openKYCNotVerifiedModal }}>
      <KYCModal />
      {children}
    </TemplateModalContext.Provider>
  );
};

export const useTemplateModal = () => {
  const context = useContext(TemplateModalContext);
  if (!context) {
    throw new Error("useKYCNotVerified must be used within a TemplateModalProvider");
  }
  return context;
};

export const useCompletedModal = (title: ReactNode, completedText: ReactNode, callback?: () => void) => {
  const t = useTranslations();
  // const [completed, setCompleted] = useState<boolean>(false);
  const [Component, disclosure] = useModal({
    content: () => (
      <div className="flex size-full flex-col items-center justify-center gap-y-4">
        <SuccessSvg />
        <div className="text-lg font-bold">{title}</div>
        <p className="text-sm !leading-normal text-gray-400">{completedText}</p>
        <Button onPress={() => callback?.()} className="w-full">
          {t("done")}
        </Button>
      </div>
    ),
  });
  return [Component, disclosure] as const;
};

export const useAlertModal = (title: string, callback?: () => void) => {
  const [alertText, setAlertText] = useState<string>("");
  const t = useTranslations();
  const [Component, disclosure] = useModal({
    content: () => (
      <div className="flex size-full flex-col items-center justify-center gap-y-4">
        <AlertSvg />
        <div className="text-lg font-bold">{title}</div>
        <p className="text-sm !leading-normal text-gray-400">{alertText}</p>
        <Button className="w-full" onPress={() => callback?.()}>
          {t("done")}
        </Button>
      </div>
    ),
  });
  return [Component, disclosure, setAlertText] as const;
};

export const useLogoutModal = (callback: () => void, rejectCallback: () => void) => {
  const t = useTranslations();
  const [component, disclosure] = useModal({
    content: () => (
      <div className="flex size-full flex-col items-center justify-center gap-y-4">
        <p className="text-sm !leading-normal text-gray-400">{t("logout-confirmation")}</p>
        <div className="flex w-full gap-x-4">
          <Button fullWidth className="w-full" variant="bordered" onPress={() => rejectCallback?.()}>
            {t("cancel")}
          </Button>
          <Button fullWidth className="w-full" onPress={() => callback?.()}>
            {t("done")}
          </Button>
        </div>
      </div>
    ),
  });
  return [component, disclosure] as const;
};

export const useConfirmationModal = (title: ReactNode, content: ReactNode, res?: () => void, rej?: () => void) => {
  const t = useTranslations();
  const [Component, disclosure] = useModal({
    content: () => (
      <div className="flex size-full flex-col items-center justify-center gap-y-4">
        <div className="text-lg font-bold">{title}</div>
        <p className="text-sm !leading-normal text-gray-400">{content}</p>
        <div className="flex w-full gap-x-4">
          <Button fullWidth className="w-full" variant="bordered" onPress={() => rej?.()}>
            {t("cancel")}
          </Button>
          <Button fullWidth className="w-full" onPress={() => res?.()}>
            {t("confirm")}
          </Button>
        </div>
      </div>
    ),
  });
  return [Component, disclosure] as const;
};

export const useModal = ({
  title,
  content,
  modalProps,
}: {
  title?: string;
  content: (closeModal?: () => void) => ReactNode | ReactNode;
  modalProps?: Omit<ModalProps, "children"> | undefined;
}) => {
  const disclosure = useDisclosure();
  const component = memo(() => (
    <Modal
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.onOpenChange}
      size="sm"
      scrollBehavior="inside"
      classNames={{
        closeButton: "hover:bg-white/5 active:bg-white/10",
      }}
      className="z-[1000]"
      placement="center"
      radius="lg"
      {...modalProps}
    >
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{content(disclosure.onClose)}</ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  ));

  return [component, disclosure] as const;
};
