"use client";
import Swal from "@/components/modal";
import AlertSvg from "@/components/svg/alert-svg";
import SuccessSvg from "@/components/svg/success-svg";
import { useTranslations } from "next-intl";
import { ReactElement, ReactNode } from "react";
import { ConfirmButtonText, KYCIncompleteText1, KYCIncompleteText2 } from "./template-text";
export const OpenNormalModal = (title: ReactNode, description: ReactNode, confirmButtonText?: ReactNode) => {
  return Swal.fire({
    showCloseButton: true,
    confirmButtonText: <>{confirmButtonText || "Done"}</>,
    iconHtml: <SuccessSvg />,
    html: (
      <div className="size-full space-y-4">
        <div className="text-lg font-bold">{title}</div>
        <p className="text-sm !leading-normal text-gray-400">{description}</p>
      </div>
    ),
  });
};

// const TransformErrorMessage = (error: string) => {
//   switch (error) {
//     case "api.security_pin_invalid":
//       return "Invalid security pin";
//     default:
//       return error;
//   }
// };

export const OpenAlertModal = (title: ReactNode, description: ReactNode, confirmButtonText?: ReactNode) => {
  return Swal.fire({
    showCloseButton: true,
    confirmButtonText: <>{confirmButtonText || "Done"}</>,
    iconHtml: <AlertSvg />,
    html: (
      <div className="size-full space-y-4">
        <div className="text-lg font-bold">{title}</div>
        <p className="text-sm !leading-normal text-gray-400">{description}</p>
      </div>
    ),
  });
};

export const OpenConfirmModal = async (title: string, description: string) => {
  const ConfirmButtonText = () => {
    const t = useTranslations();
    return <>{t("confirm")}</>;
  };
  const CancelButtonText = () => {
    const t = useTranslations();
    return <>{t("cancel")}</>;
  };
  return Swal.fire({
    showCloseButton: true,
    confirmButtonText: <ConfirmButtonText />,
    cancelButtonText: <CancelButtonText />,
    html: (
      <div className="size-full space-y-4">
        <div className="text-lg font-bold !leading-normal">{title}</div>
        <p className="text-sm !leading-normal text-gray-400">{description}</p>
      </div>
    ),
    showCancelButton: true,
  });
};

export const OpenNonIconModal = (
  title: string,
  showConfirmButton = true,
  htmlDescription: React.ReactNode,
  confirmButtonText: string = "Next"
) => {
  Swal.fire({
    showCloseButton: true,
    confirmButtonText,
    html: (
      <div className="size-full space-y-4">
        <div className="text-lg font-bold !leading-normal">{title}</div>
        <>{htmlDescription}</>
      </div>
    ),
    showConfirmButton,
  });
};

export const OpenKYCIncompleteModal = () => {
  return OpenAlertModal(<KYCIncompleteText1 />, <KYCIncompleteText2 />);
};

export const WrongPasswordTemplate = () => {
  const t = useTranslations();
  return (
    <div className="size-full space-y-4">
      <div className="text-lg font-bold">{t("oops")}</div>
      <p className="text-sm text-gray-400">{t("login-failed")}</p>
    </div>
  );
};
