export interface CryptoChainTypeRes {
  id: number;
  name: string;
  status: number;
  created_at: string;
  updated_at: string;
  encrypted_id: string;
}

export interface CryptoChainAddress {
  id: number;
  chain_id: number;
  usdt_address: string;
  status: number;
  created_at: string;
  updated_at: string;
  encrypted_id: string;
  chain: {
    id: number;
    name: string;
    status: number;
    created_at: string;
    updated_at: string;
  };
}

export interface UserBank {
  id: number;
  user_id: number;
  chain_id: number;
  usdt_address: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  encrypted_id: string;
}

export interface TeamUser {
  id: number;
  username: string;
  email: string;
  ranking: string;
  encrypted_id: string;
  total_group_sale: TotalGroupSale;
  direct_sponsors: DirectSponsors;
}

export interface DirectSponsors {
  downlineCount: number;
  total_sale: string;
}

export interface TotalGroupSale {
  downlineCount: number;
  userProfit: string;
  total_sale: string;
  downlineCountImprovement: string;
  totalSaleImprovement: string;
}

// export interface UserDetails {
//   id: number;
//   country_id: number;
//   ranking_id: null;
//   old_id: null;
//   username: string;
//   email: string;
//   calling_code: string;
//   phone_number: string;
//   invitation_code: string;
//   capital: string;
//   is_free: number;
//   created_at: Date;
//   user_detail: UserDetail;
// }

// export interface UserDetail {
//   id: number;
//   user_id: number;
//   fullname: string;
//   photo: null;
//   created_at: Date;
//   updated_at: Date;
//   photo_path: string | undefined;
// }

export interface CreateDepositResponseData {
  message: string;
  data: Data;
}

export interface Data {
  user_id: number;
  txid: string;
  to_wallet_id: number;
  txid_date: Date;
  usdt_address: string;
  chain_id: number;
  status: number;
  updated_at: Date;
  created_at: Date;
  id: number;
}

export interface Notifications {
  total_unread: number;
  notifications: Notification[];
}

export interface Notification {
  id?: number;
  user_id?: string;
  title?: string;
  content?: string;
  system_title?: string;
  system_content?: string;
  meta_data?: string;
  url_slug?: string;
  image?: string;
  type?: number;
  status?: number;
  created_at: string;
  updated_at: string;
  is_read?: number;
}

export enum ServiceChargeType {
  PERCENTAGE = "percentage",
  FLAT = "flat",
}

// export interface GetUserDetailsResponse {
//   message: string;
//   message_key: string;
//   data: {
//     id: number;
//     country_id: number;
//     ranking_id: number | null;
//     old_id: number | null;
//     username: string;
//     email: string;
//     calling_code: string;
//     phone_number: string;
//     invitation_code: string;
//     capital: string;
//     is_free: number;
//     created_at: string;
//     user_address: string | undefined;
//     chain_type: string | undefined;
//   };
// }

export interface UserDetails {
  id: number;
  country_id: null;
  ranking_id: null;
  old_id: null;
  username: string;
  email: string;
  calling_code: string;
  phone_number: string;
  invitation_code: string;
  capital: string;
  is_free: number;
  created_at: string;
  ib_permission: boolean;
  user_detail: {
    id: number;
    user_id: number;
    fullname: null;
    photo: string;
    created_at: Date;
    updated_at: Date;
    photo_path: string;
  };
}

export interface GetAllInvestmentPlansReturnType {
  id: number;
  name: string;
  desc: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  encrypted_id: string;
  document: any[];
  contract: Contract[];
}

export interface Contract {
  id: number;
  investment_plan_id: number;
  period: string;
  penalty: string;
  commission: string;
  fee: string;
  profit: string;
  min_deposit: string;
  multiple: string;
  commission_get_at: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  display_apy: string;
}

export enum walletType {
  usdt = "usdt",
  passiveIncome = "passive-income",
  activeIncome = "active-income",
  trading = "trading",
}

export interface InitMyTeamReturnType {
  id: number;
  username: string;
  email: string;
  ranking: string;
  encrypted_id: string;
  total_group_sale: TotalGroupSale;
  direct_sponsors: DirectSponsors;
}

export interface DirectSponsors {
  downlineCount: number;
  total_sale: string;
}

export interface TotalGroupSale {
  downlineCount: number;
  userProfit: string;
  _total_sale: string;
  total_sale: string;
  downlineCountImprovement: string;
  totalSaleImprovement: string;
}

export interface OnePlanResponse {
  id: number;
  name: string;
  desc: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  encrypted_id: string;
  contract: Contract[];
}

export interface Contract {
  id: number;
  investment_plan_id: number;
  period: string;
  penalty: string;
  commission: string;
  fee: string;
  profit: string;
  min_deposit: string;
  multiple: string;
  commission_get_at: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  display_apy: string;
  graph_data: GraphData[];
  encrypted_id: string;
  summary: Summary;
}

export interface GraphData {
  date: string;
  total_profit: string;
  profit_date_unix: number;
}

export interface Summary {
  average_monthly: string;
  this_month_average: string;
  total_accumulated: number;
}

export interface AllProfitGraphReturn {
  graph_data: {
    date: string;
    total_profit: string;
    profit_date_unix: number;
  }[];
}

export interface MT5GetAllAccountReturnType {
  mt5_accounts: Mt5Account[];
  draw: null;
  recordsFiltered: number;
  recordsTotal: number;
}

export interface Mt5Account {
  user_id: number;
  type: number;
  account_type: number;
  account_number?: string;
  balance: string;
  leverage: string;
  status: number;
  created_at: string;
  updated_at: string;
  display_type: string;
  display_account_type: string;
  listing_balance: string;
  encrypted_id: string;
  user: User;
}

export interface User {
  id: number;
  country_id: null;
  referral_id: number;
  ranking_id: null;
  old_id: null;
  username: string;
  email: string;
  calling_code: string;
  phone_number: string;
  two_factor_secret: null;
  two_factor_recovery_codes: null;
  invitation_code: string;
  referral_structure: string;
  capital: string;
  status: number;
  is_free: number;
  email_verified_at: null;
  created_at: string;
  updated_at: string;
}

export interface AccountDealsResponse {
  mt5_deals: Mt5Deal[];
  draw: null;
  recordsFiltered: number;
  recordsTotal: number;
  subTotal: string[];
  grandTotal: string[];
  equity: number;
}

export interface Mt5Deal {
  id: number;
  user_id: number;
  account_id: number;
  amount: string;
  is_calculate: number;
  type: number;
  status: number;
  created_at: Date;
  updated_at: Date;
  deal_id: string;
  trade_at: Date;
  order_id: string;
  action: number;
  display_action: string;
  entry: number;
  contract_size: string;
  time: Date;
  timeMsc: Date;
  symbol: string;
  swap: string;
  open_price: string;
  open_trade_at: string;
  price: string;
  volume: string;
  profit: string;
  commission: string;
  display_commission: string;
  price_position: string;
  display_trade_at: Date;
  encrypted_id: string;
  user: User;
  account: Account;
}

export interface Account {
  user_id: number;
  type: number;
  account_type: number;
  account_number: string;
  balance: string;
  leverage: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  remarks: null;
  last_check_at: Date;
  display_type: string;
  display_account_type: string;
  listing_balance: string;
  encrypted_id: string;
}

export interface User {
  id: number;
  country_id: null;
  referral_id: number;
  ranking_id: null;
  old_id: null;
  username: string;
  email: string;
  calling_code: string;
  phone_number: string;
  two_factor_secret: null;
  two_factor_recovery_codes: null;
  invitation_code: string;
  referral_structure: string;
  capital: string;
  status: number;
  is_free: number;
  email_verified_at: null;
  created_at: string;
  updated_at: string;
  ib_permission: boolean;
}

export enum E_MT5AccountStatus {
  PENDING = 1,
  PROCESSING = 2,
  ACTIVE = 10,
  INACTIVE = 11,
  REJECTED = 20,
  TERMINATED = 21,
}


export interface MT5TeamUser {
  id:               number;
  username:         string;
  email:            string;
  ib_permission:    boolean;
  mt5_ranking:      number;
  mt5_ranking_path: string;
  encrypted_id:     string;
  today_bonus:      number;
  today_volume:     number;
  total_volume:     number;
  total_bonus:      number;
  downline_count:   number;
  active_balance:   number;
}

export interface WalletDetailsResponse {
  id:           number;
  user_id:      number;
  balance:      string;
  type:         number;
  status:       number;
  created_at:   string;
  updated_at:   string;
  encrypted_id: string;
}
