"use client";

import clsx from "clsx";
import Swal from "sweetalert2";
import withReactContent, { ReactSweetAlert } from "sweetalert2-react-content";
const CustomSwal = withReactContent(Swal);

const e = CustomSwal.mixin({
  target: "#swal",
  customClass: {
    confirmButton: clsx("outline-none rounded-lg text-black text-base bg-primary h-10 w-full leading-none p-0 m-0 order-last"),
    cancelButton: clsx(
      "rounded-lg text-black text-base bg-white h-10 w-full leading-none p-0 m-0 border-solid !border-1 !border-primary"
    ),
    title: clsx("text-black text-lg font-bold p-0 m-0"),
    popup: clsx("rounded-lg bg-white p-6 m-0 !block [&_*]:leading-none"),
    // container: clsx("backdrop-blur-sm"),
    htmlContainer: clsx("!p-0 !py-1 !block !overflow-visible"),
    actions: clsx("!p-0 !mt-6 !flex-row !flex-nowrap gap-x-4"),
    icon: clsx("!p-0 !m-0 !mx-auto border-none size-fit"),
    closeButton: clsx("!shadow-none !outline-none !p-0 !m-0 !absolute !top-0 !right-0 !mr-4 !mt-[0.8rem] hover:text-gray-500"),
  },
}) as typeof Swal & ReactSweetAlert;

export default e;
