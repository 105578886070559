"use client";

import { redirect, usePathname, useRouter } from "@/i18n/routing";
import { GetUserDetail } from "@/lib/actions";
import { useParams } from "next/navigation";
import * as React from "react";

export default function SessionChecker({
  children,
  // params: { locale },
}: {
  readonly children: React.ReactNode;
  // params: { readonly locale: string };
}) {
  const pathname = usePathname();
  const router = useRouter();
  const { locale } = useParams();
  // const regexPath = /^(\/(login|register|password))/g;
  React.useEffect(() => {
    router.refresh();
    // React.startTransition(async () => {
    //   try {
    //     // const res = await GetUserDetail();

    //     // console.log("SESSION_CHECKER: ", pathname);
    //     // console.log("SESSION_CHECKER: ", regexPath.test(pathname));
    //     // console.log("SESSION_CHECKER: ", res);

    //     // if (!regexPath.test(pathname)) {
    //     //   console.log("hey");

    //     //   console.log("error" in res && res.error === "SESSION_ENDED");
    //     //   if ("error" in res && res.error === "SESSION_ENDED") {
    //     //     router.push("/login");
    //     //     // redirect({ href: "/login", locale: locale as string });
    //     //   }
    //     // } else {
    //     //   console.log("lol");

    //     //   if ("username" in res && regexPath.test(pathname)) {
    //     //     router.push("/");
    //     //     // redirect({ href: "/", locale: locale as string });
    //     //   }
    //     // }
    //   } catch (error: any) {
    //     console.log("SESSION_CHECKER ERROR: ", error);
    //     // if ("error" in error && error.error === "SESSION_ENDED") router.push("/login");
    //   }
    // });
  }, [pathname, locale]);
  return <>{children}</>;
}
