"use client";

import { createContext, useContext, useMemo } from "react";

type KYCContextType = {
  KYCStatusCode: "KYC_COMPLETED" | "KYC_NOT_FOUND" | "KYC_PENDING";
};

const KYCContext = createContext<KYCContextType | null>(null);

export function KYCStatusProvider({ children, KYCStatus }: { children: React.ReactNode; KYCStatus: any }) {
  const KYCStatusCode = useMemo<"KYC_COMPLETED" | "KYC_NOT_FOUND" | "KYC_PENDING">(() => {
    // console.log(KYCStatus);

    if (typeof KYCStatus === "string") {
      return "KYC_NOT_FOUND";
    }
    /*
      status code for KYC

      enum KYCStatus {
        NoSubmission = 0,
        Pending = 2,
        Reviewing = 3,
        Resubmission = 20,
        Completed = 10,
      }
    */
    // if (KYCStatus.status !== 10) {
    //   return "KYC_NOT_FOUND";
    // }

    switch (KYCStatus.status) { 
      case 0:
        return "KYC_NOT_FOUND";
      case 2:
      case 3:
      case 20:
        return "KYC_PENDING";
      case 10:
        return "KYC_COMPLETED";
      default:
        return "KYC_NOT_FOUND";
    }

    // if (KYCStatus.status === 2) {
    //   return "KYC_PENDING";
    // }

    // return "KYC_COMPLETED";
  }, [KYCStatus]);

  // console.log(KYCStatusCode);
  

  return <KYCContext.Provider value={{ KYCStatusCode }}>{children}</KYCContext.Provider>;
}

export function useKYCStatus() {
  const context = useContext(KYCContext);
  if (!context) {
    throw new Error("useKYCStatus must be used within a KYCContext");
  }
  return context;
}
