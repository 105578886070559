export default function SuccessSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <g clipPath="url(#clip0_161_960)">
        <path
          d="M60.0037 120.007C93.1428 120.007 120.007 93.1428 120.007 60.0037C120.007 26.8645 93.1428 0 60.0037 0C26.8645 0 0 26.8645 0 60.0037C0 93.1428 26.8645 120.007 60.0037 120.007Z"
          fill="white"
        />
        <path
          d="M114.83 59.9964C114.83 65.6631 113.969 71.1326 112.377 76.2734C112.274 76.5947 112.172 76.9233 112.063 77.2373C111.836 77.9238 111.595 78.6102 111.332 79.282C111.135 79.8078 110.931 80.3335 110.704 80.852C104.446 96.0921 91.4918 107.893 75.4996 112.588C75.0542 112.72 74.6087 112.851 74.1633 112.961C73.9077 113.034 73.6521 113.099 73.3892 113.165C73.0898 113.245 72.7831 113.311 72.4837 113.384C68.4674 114.326 64.2978 114.815 59.9967 114.815C29.7137 114.815 5.16309 90.2721 5.16309 59.9818C5.16309 29.6916 29.721 5.17017 60.004 5.17017C90.2869 5.17017 114.83 29.7208 114.83 60.0037V59.9964Z"
          fill="#FFE179"
        />
        <path
          d="M60.0039 107.827C33.6351 107.827 12.1807 86.3726 12.1807 60.0037C12.1807 33.6348 33.6278 12.1731 60.0039 12.1731C86.3801 12.1731 107.827 33.6275 107.827 59.9964C107.827 86.3653 86.3728 107.82 60.0039 107.82V107.827ZM60.0039 16.0945C35.7966 16.0945 16.102 35.789 16.102 59.9964C16.102 84.2038 35.7966 103.898 60.0039 103.898C84.2113 103.898 103.906 84.2038 103.906 59.9964C103.906 35.789 84.2113 16.0945 60.0039 16.0945Z"
          fill="white"
        />
        <path
          d="M77.4067 47.4975C78.4229 48.5137 78.4229 50.164 77.4067 51.1802L56.5949 71.992C55.5787 73.0082 53.9284 73.0082 52.9122 71.992L42.5063 61.5861C41.4901 60.5699 41.4901 58.9196 42.5063 57.9034C43.5225 56.8872 45.1728 56.8872 46.189 57.9034L54.7576 66.4639L73.7321 47.4975C74.7483 46.4813 76.3986 46.4813 77.4148 47.4975H77.4067Z"
          fill="#212121"
        />
      </g>
      <defs>
        <clipPath id="clip0_161_960">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
