import { useTranslations } from "next-intl";

const KYCIncompleteText1 = () => {
  const t = useTranslations();
  return <>{t("kyc-incomplete.text1")}</>;
};

const KYCIncompleteText2 = () => {
  const t = useTranslations();
  return <>{t("kyc-incomplete.text2")}</>;
};

const ConfirmButtonText = () => {
  const t = useTranslations();
  return <>{t("done")}</>;
};

export { KYCIncompleteText1, KYCIncompleteText2, ConfirmButtonText };
