import {OpenAlertModal, OpenKYCIncompleteModal} from "@/components/template-modal";
import {GetUserKYCStatus} from "./actions";
import {response422} from "./api";
import {walletType} from "./declaration";

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);

export {dayjs};

export function formatNumber(numberString: string) {
    // Parse the string to a float
    const number = parseFloat(numberString);

    // Use Intl.NumberFormat to format the number
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(number);
}

// Custom error with passing JSON data
export class ApiError extends Error {
    errorData: any;

    constructor(message: string, errorData: any) {
        super(message);
        this.name = "ApiError";
        this.errorData = errorData;
    }
}

// export const checkKycStatus = async (nextFunc: () => void, errorFunc = () => {}) => {
//   const errorAction = () => {
//     OpenKYCIncompleteModal();
//     errorFunc();
//   };

//   GetUserKYCStatus().then((res) => {
//     /* This part is to check if the kyc . If it's empty, the server would return a string value like "". Hence check the return with 'typeof' below */
//     if (typeof res === "string") {
//       errorAction();
//       return;
//     }
//     const { status }: { status: number } = res;
//     /*
//     status code for KYC

//     enum KYCStatus {
//       NoSubmission = 0,
//       Pending = 2,
//       Reviewing = 3,
//       Resubmission = 20,
//       Completed = 10,
//     }
//      */
//     if (status !== 10) {
//       errorAction();
//       return;
//     }
//     nextFunc();
//   });
// };

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

interface Errors {
    [key: string]: string[];
}

export const getErrorMessages = (errors: Errors) => {
    if (!errors) return [];
    return Object.values(errors).flat();
};

export const getWalletTypeAndBalance = (pathname: string, balanceData: any) => {
    if (pathname.includes(walletType.usdt)) {
        return {walletType: walletType.usdt, balance: balanceData ? balanceData[1] : "loading"};
    } else if (pathname.includes(walletType.passiveIncome)) {
        return {walletType: walletType.passiveIncome, balance: balanceData ? balanceData[2] : "loading"};
    } else if (pathname.includes(walletType.activeIncome)) {
        return {walletType: walletType.activeIncome, balance: balanceData ? balanceData[3] : "loading"};
    } else if (pathname.includes(walletType.trading)) {
        return {walletType: walletType.trading, balance: balanceData ? balanceData[4] : "loading"};
    }
    return {walletType: null, balance: "loading"};
};

// export const isValidToSubmit = (amount: number, currentWalletBalance: number, securityPin: string) => {
//   if (amount > currentWalletBalance) {
//     OpenAlertModal("Not enough balance", "Please enter a valid amount");
//     return false;
//   }
//   if (amount <= 0) {
//     OpenAlertModal("Invalid Amount", "Please enter a valid amount");
//     return false;
//   }
//   if (securityPin.length != 6) {
//     OpenAlertModal("Invalid Security Pin", "Security Pin must be 6 digits");
//     return false;
//   }
//   return true;
// };

export const formatDateTime = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const removeLastFiveCharacters = (text: string): string => {
    return text.slice(0, -5);
};

export const removeLastFiveCharactersLower = (text: string): string => {
    return text.slice(0, -5).toLowerCase();
};

export const getFilteredDate = (index: string) => {
    const today = new Date();
    let startDate: Date;

    switch (index) {
        case "0":
            startDate = new Date("2021-01-01");
            break;
        case "1":
            startDate = new Date(today);
            break;
        case "2":
            startDate = new Date(today);
            startDate.setDate(today.getDate() - 1);
            break;
        case "3":
            startDate = new Date(today);
            startDate.setDate(today.getDate() - 7);
            break;
        case "4":
            startDate = new Date(today);
            startDate.setDate(today.getDate() - 30);
            break;
        // case "6 Months":
        //   startDate = new Date(today);
        //   startDate.setMonth(today.getMonth() - 6);
        //   break;
        // case "1 Year":
        //   startDate = new Date(today);
        //   startDate.setFullYear(today.getFullYear() - 1);
        //   break;
        // case "2 Years":
        //   startDate = new Date(today);
        //   startDate.setFullYear(today.getFullYear() - 2);
        //   break;
        // case "3 Years":
        //   startDate = new Date(today);
        //   startDate.setFullYear(today.getFullYear() - 3);
        //   break;
        default:
            startDate = new Date("2021-01-01");
            break;
    }

    const formatDate = (date: Date): string => {
        return date.toISOString().split("T")[0]; // Return date in YYYY-MM-DD format
    };

    return `${formatDate(startDate)} to ${formatDate(today)}`;
};

export const getGraphDateRange = (minDate: string, dateArray: string[], index: string) => {
    const date = new Date(minDate);
    let maxDate: Date;
    // if deselect everything in filter will gives 0, so we need to +1 to get the correct index
    if (Number(index) === 0) {
        return null;
    } else {
        index = (Number(index) - 1).toString();
    }
    switch (dateArray[Number(index)]) {
        case "6 Months":
            maxDate = new Date(date.setMonth(date.getMonth() + 6));
            break;
        case "1 Year":
            maxDate = new Date(date.setFullYear(date.getFullYear() + 1));
            break;
        case "2 Years":
            maxDate = new Date(date.setFullYear(date.getFullYear() + 2));
            break;
        case "3 Years":
            maxDate = new Date(date.setFullYear(date.getFullYear() + 3));
            break;
        default:
            return null;
    }
    const formatDate = (date: Date): string => {
        return date.toISOString().split("T")[0]; // Return date in YYYY-MM-DD format
    };
    return [formatDate(date), formatDate(maxDate)];
};

export const standardEpoch = (unix: number) => {
    return Number(unix.toString().padEnd(13, "0"));
};

enum requestType {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export const safeHandling = async <T>(func: () => Promise<T | response422>, errorTitle: string) => {
    try {
        const res = await func();
        if ("errors" in (res as response422)) {
            OpenAlertModal("Error", `${errorTitle} ` + getErrorMessages((res as response422).errors as any));
            return;
        }
        return res as T;
    } catch (e) {
        if (e instanceof ApiError) {
            console.error(e.errorData);
        } else {
            console.error(e);
        }
    }
};

function _toFixed(num: number, fixed: number): string {
    // Ensure 'num' is a valid number
    if (isNaN(num) || typeof num !== "number") {
        num = 0;
    }

    // Use regex to format the number with specified decimal places
    const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed || -1}})?`);
    return String(num).match(re)?.[0] || "0";
}

export function currencyFormat(number: number, decimal: number, displayComma: boolean = false): string {
    // Validate the number and decimal inputs
    if (isNaN(number) || typeof number !== "number") {
        number = 0;
    }

    if (isNaN(decimal) || typeof decimal !== "number" || decimal < 0) {
        throw new Error("Decimal must be a non-negative number.");
    }

    const num = _toFixed(number, decimal);

    if (displayComma) {
        // Format with commas using Intl.NumberFormat
        const formatter = new Intl.NumberFormat("en", {
            maximumFractionDigits: decimal,
            minimumFractionDigits: decimal,
        });
        return formatter.format(parseFloat(num));
    } else {
        // Format without commas
        return parseFloat(num).toFixed(decimal);
    }
}

export const numberFormat = (value: number) => {
    return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export function getRank(rank: string) {
    switch (rank) {
        case "M0":
            return "AGENT";
        case "M1":
            return "SA";
        case "M2":
            return "MA";
        case "M3":
            return "RM";
        case "M4":
            return "RD";
        case "M5":
            return "Co.Partner";
        default:
            return "-";
    }
}
