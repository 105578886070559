"use client";

import { useTranslations } from "next-intl";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { SweetAlertResult } from "sweetalert2";
import Swal from "@/components/modal";
import AlertSvg from "@/components/svg/alert-svg";
import SuccessSvg from "@/components/svg/success-svg";
import { currencyFormat } from "@/lib/helper";

/*
  const {
    OpenNormalModal,
    OpenAlertModal,
    OpenConfirmModal,
    OpenKYCIncompleteModal,
    OpenNonIconModal,
    OpenWrongPasswordModal,
    OpenLogoutModal,
    OpenSuccessDepositModal
  } = useSwalModal();
 */

interface SwalModalContextType {
  OpenNormalModal: (title: ReactNode, description: ReactNode) => Promise<SweetAlertResult>;
  OpenAlertModal: (title: ReactNode, description: ReactNode) => Promise<SweetAlertResult>;
  OpenConfirmModal: (title: string, description: string) => Promise<SweetAlertResult>;
  OpenNonIconModal: (
    title: React.ReactNode,
    showConfirmButton: boolean,
    htmlDescription: React.ReactNode
  ) => Promise<SweetAlertResult>;
  OpenWrongPasswordModal: () => Promise<SweetAlertResult>;
  OpenKYCIncompleteModal: () => Promise<SweetAlertResult>;
  OpenLogoutModal: () => Promise<SweetAlertResult>;
  OpenSuccessDepositModal: (txid: string, amount: number) => Promise<SweetAlertResult>;
}

const SwalModalContext = createContext<SwalModalContextType | null>(null);

export const SwalModalProvider = ({ children }: { children: ReactNode }) => {
  const t = useTranslations();
  const SucceedModalContent = ({ txid, amount }: { txid: string; amount: number }) => {
    return (
      <div className="size-full space-y-4">
        <div className="text-lg font-bold !leading-normal">{t('deposit-success-modal.text1')}</div>
        <p className="text-sm">{t('deposit-success-modal.text2')}</p>
        <div className="mx-auto grid max-w-[320px] grid-cols-2 gap-x-4 gap-y-2 text-sm">
          <div className="text-right">TXID:</div>
          <div className="text-left font-bold">{txid}</div>
          <div className="text-right">{t('deposit-success-modal.text4')}</div>
          <div className="text-left font-bold">USDT {currencyFormat(amount, 2, true)}</div>
          {/* <div className="text-left">
            TXID: <span className="font-bold">{txid}</span>
          </div>
          <div className="text-left">
            Deposit Amount: <span className="font-bold">USDT {amount.toLocaleString()}</span>
          </div> */}
        </div>
        {/* <p className="text-sm !leading-normal text-gray-400">You successfully deposited ${ }</p> */}
      </div>
    );
  };

  const OpenNormalModal = (title: ReactNode, description: ReactNode) => {
    return Swal.fire({
      showCloseButton: true,
      confirmButtonText: t("done"),
      iconHtml: <SuccessSvg />,
      html: (
        <div className="size-full space-y-4">
          <div className="text-lg font-bold">{title}</div>
          <p className="text-sm !leading-normal text-gray-400">{description}</p>
        </div>
      ),
    });
  };

  const OpenAlertModal = (title: ReactNode, description: ReactNode) => {
    return Swal.fire({
      showCloseButton: true,
      confirmButtonText: t("okay"),
      iconHtml: <AlertSvg />,
      html: (
        <div className="size-full space-y-4">
          <div className="text-lg font-bold">{title}</div>
          <p className="text-sm !leading-normal text-gray-400">{description}</p>
        </div>
      ),
    });
  };

  const OpenNonIconModal = (title: React.ReactNode, showConfirmButton: boolean, htmlDescription: React.ReactNode) =>
    Swal.fire({
      showCloseButton: true,
      confirmButtonText: t("okay"),
      html: (
        <div className="size-full space-y-4">
          <div className="text-lg font-bold !leading-normal">{title}</div>
          <>{htmlDescription}</>
        </div>
      ),
      showConfirmButton,
    });

  const OpenConfirmModal = async (title: string, description: string) => {
    return Swal.fire({
      showCloseButton: true,
      confirmButtonText: t("confirm"),
      cancelButtonText: t("cancel"),
      html: (
        <div className="size-full space-y-4">
          <div className="text-lg font-bold !leading-normal">{title}</div>
          <p className="text-sm !leading-normal text-gray-400">{description}</p>
        </div>
      ),
      showCancelButton: true,
    });
  };

  const OpenWrongPasswordModal = () =>
    Swal.fire({
      iconHtml: <AlertSvg />,
      showCloseButton: true,
      html: (
        <div className="size-full space-y-4">
          <div className="text-lg font-bold">{t("oops")}</div>
          <p className="text-sm text-gray-400">{t("login-failed")}</p>
        </div>
      ),
    });

  const OpenKYCIncompleteModal = () => OpenAlertModal(t("kyc-incomplete.text1"), t("kyc-incomplete.text2"));

  const OpenLogoutModal = () =>
    Swal.fire({
      width: "320px",
      html: (
        <div className="mt-10 size-full space-y-4">
          <p className="text-sm text-gray-400">{t("logout-confirmation")}</p>
        </div>
      ),
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t("confirm"),
      cancelButtonText: t("cancel"),
    });

  const OpenSuccessDepositModal = (txid: string, amount: number) => {
    return Swal.fire({
      // showCloseButton: true,
      confirmButtonText: t("confirm"),
      cancelButtonText: t("cancel"),
      html: <SucceedModalContent txid={txid} amount={Number(amount)} />,
      // showCancelButton: true,
    });
  };


  // useEffect(() =>
  // {
  //   window.addEventListener("keydown", (e) =>
  //   { 
  //     if (e.key === "M") {
  //       OpenSuccessDepositModal("123456", 1000);
  //     }
  //   });
  //  }, []);

  return (
    <SwalModalContext.Provider
      value={{
        OpenNormalModal,
        OpenAlertModal,
        OpenConfirmModal,
        OpenNonIconModal,
        OpenWrongPasswordModal,
        OpenKYCIncompleteModal,
        OpenLogoutModal,
        OpenSuccessDepositModal
      }}
    >
      {children}
    </SwalModalContext.Provider>
  );
};

export default function useSwalModal() {
  const context = useContext(SwalModalContext);
  if (!context) {
    throw new Error("useSwalModal must be used within a SwalModalProvider");
  }
  return context;
}
