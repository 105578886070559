import dynamic from "next/dynamic";
import React, { useEffect, useMemo } from "react";
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { AllProfitGraphReturn, GraphData } from "@/lib/declaration";
import { GetAllProfitGraph, GetUserDetail } from "@/lib/actions";
import { useTranslations } from "next-intl";
import _ from "lodash";
dayjs.extend(LocalizedFormat);

interface chartOptionsType {
  options: ApexCharts.ApexOptions;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
}

export const LineAreaChart = () => {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const [pending, startPending] = React.useTransition();
  const [graphData, setGraphData] = React.useState<AllProfitGraphReturn>();
  const t = useTranslations();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640); // sm breakpoint in Tailwind is 640px
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // call it initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    startPending(async () => {
      try {
        const today = dayjs().format("YYYY-MM-DD");
        const dateRange = "2024-11-01 to " + today;
        const res = await GetAllProfitGraph(dateRange);
        setGraphData(res);
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const chartOptions: chartOptionsType = useMemo(() => {
    if (!graphData) return { options: {}, series: [] };
    return {
      options: {
        // colors: ['#008FFB', '#00E396', '#CED4DC'],
        chart: {
          id: "area",
          type: "area",
          height: 240,
          zoom: {
            autoScaleYaxis: true,
          },

          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          colors: ["#FFCA05"],
          opacity: 0.5,
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          type: "datetime",
          // categories,
          // tickAmount: 4,
          // min: categories[0],
          // max: categories[categories.length - 1],
          labels: {
            formatter: function (_value: string, timestamp: number): string {
              return dayjs(timestamp).format("DD MMM YY");
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value: number) {
              try {
                return "$" + value.toFixed(2);
              } catch (e) {
                return "$0";
              }
            },
          },
          // max: Math.max(...graphData?.map((item: any) => parseFloat(item.profit))!) + 100,
          // min: 0,
        },
      },
      series: [
        {
          name: "Profit",
          data: graphData.graph_data
            .sort((a, b) => dayjs(a.date, "YYYY-MM-DD").unix() - dayjs(b.date, "YYYY-MM-DD").unix())
            .map((item) => [dayjs(item.date, "YYYY-MM-DD").unix() * 1000, parseFloat(item.total_profit)]),
        },
      ],
    } as chartOptionsType;
  }, [graphData]);

  // if (!graphData) return <div>Loading...</div>;
  if (!pending && graphData)
    return <Chart options={chartOptions.options} series={chartOptions.series} height={320} type="area" />;
  else
    return (
      <div className="h-80 px-4">
        <div className="flex h-full items-center justify-center">
          {_.isNull(graphData) ? <span>{t("no-data")}</span> : pending && <span>{t("loading")}</span>}
        </div>
      </div>
    );
};
